$(document).on("turbolinks:load", function () {
  $(".delete_ajax").on("click", function (e) {
    e.preventDefault();
    button = $(this);
    swal({
      title: "Are you sure?",
      text: $(this).data("delete"),
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        $.ajax({
          url: $(this).attr("href"),
          dataType: "JSON",
          method: "DELETE",
          success: function () {
            $(button)
              .parents(".col-sm-2")
              .remove();
          }
        });
      }
      setTimeout(function () {
        location.reload();
      }, 300);
    });
  });

  $(".delete_ajax2").on("click", function (e) {
    e.preventDefault();
    button = $(this);
    swal({
      title: "Are you sure?",
      text: $(this).data("delete"),
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        $.ajax({
          url: $(this).attr("href"),
          dataType: "JSON",
          method: "DELETE",
          success: function () {
            $(button)
              .parents(".row")
              .remove();
          }
        });
        window.history.back()
        setTimeout(function () {
          location.reload();
        }, 30);
      }

    });
  });

});