// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = $;
window.$ = $;
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// custom instaleld
require("bootstrap/dist/js/bootstrap");
require("jquery-validation/dist/jquery.validate");
require("select2/dist/js/select2.js");
require("trix");
require("@rails/actiontext");
require("sweetalert");
require("packs/modal-confirm");
require("flatpickr/dist/flatpickr");

import "@fortawesome/fontawesome-free/js/all";
// document.addEventListener("turbolinks:load", () => {
//   flatpickr("[data-behavior='flatpickr']", {
//     altInput: true,
//     altFormat: "F j, Y",
//     dateFormat: "Y-m-d",
//   })
// })

// datepicker
flatpickr(".datepicker");

// theme javascript
require("packs/adminlte/AdminLTE");

$(document).on("turbolinks:load", function () {
  // select2
  $(".select2").select2({
    theme: "bootstrap4",
  });
});